import { reduce } from 'lodash';
import { types as sdkTypes } from './sdkLoader';
import { TIME_SLOT_TIME } from './types';

const { UUID } = sdkTypes;

export const getDummyBooking = ({ start, end }) => ({
  id: new UUID('00000000-0000-0000-0000-000000000000'),
  type: 'booking',
  attributes: {
    end,
    start,
  },
});

export const getDummyTimeSlot = ({ start, end }) => {
  return {
    id: new UUID('00000000-0000-0000-0000-000000000000'),
    type: TIME_SLOT_TIME,
    attributes: {
      type: TIME_SLOT_TIME,
      seats: 1,
      start,
      end,
    },
  };
};

export const findCategoryNameById = (categories, id) => {
  return reduce(
    categories,
    (result, obj) => {
      if (result) return result;
      if (obj.id === id) return obj.name;
      if (obj.subcategories && obj.subcategories.length > 0) {
        return findCategoryNameById(obj.subcategories, id);
      }
      return null;
    },
    null
  );
};

export const filterByIdContains = (array, searchString) => {
  const filteredArray = array.filter(item => item.id.includes(searchString));

  if (filteredArray.length === 0) return array;

  return filteredArray;
};
