import React from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';

import { IconHelp, IconInfo } from '../../components';

import css from './InfoBox.module.css';

const InfoBox = props => {
  const { rootClassName, className, title, text, withBorder, isHelp, noIcon } = props;

  const classes = classNames(rootClassName || css.root, className, {
    [css.withBorder]: withBorder,
  });

  return (
    <div className={classes}>
      {noIcon ? null : isHelp ? (
        <IconHelp className={css.icon} />
      ) : (
        <IconInfo className={css.icon} />
      )}

      <div className={css.textWrapper}>
        {title ? <span className={css.title}>{title}</span> : null}
        <span className={css.text}>{text}</span>
      </div>
    </div>
  );
};

InfoBox.defaultProps = {
  rootClassName: null,
  className: null,
  title: null,
  text: null,
  withBorder: false,
  isHelp: false,
  noIcon: false,
};

InfoBox.propTypes = {
  rootClassName: string,
  className: string,
  title: string,
  text: string,
  withBorder: bool,
  isHelp: bool,
  noIcon: bool,
};

InfoBox.displayName = 'InfoBox';

export default InfoBox;
