import React from 'react';
import { arrayOf, oneOfType, string } from 'prop-types';
import classNames from 'classnames';
import { propTypes } from '../../util/types';

import ResponsiveImage from '../ResponsiveImage/ResponsiveImage';

import css from './ImageGallery.module.css';

const ImageGallery = props => {
  const { className, rootClassName, alt, images } = props;
  const classes = classNames(rootClassName || css.root, className);

  const getVariants = image =>
    image ? Object.keys(image?.attributes?.variants).filter(k => k.startsWith('scaled')) : [];

  const hasMultipleImages = images.length >= 5;

  return (
    <div className={classes}>
      <div className={hasMultipleImages ? css.mainContainer : css.singleImage}>
        <div className={css.mainImgWrapper}>
          <ResponsiveImage
            className={css.image}
            alt={alt}
            image={images[0]}
            variants={getVariants(images[0])}
          />
        </div>
      </div>
      {hasMultipleImages ? (
        <>
          <div className={css.container}>
            <div className={css.imgWrapper}>
              <ResponsiveImage
                className={css.image}
                alt={alt}
                image={images[1]}
                variants={getVariants(images[1])}
              />
            </div>
            <div className={css.imgWrapper}>
              <ResponsiveImage
                className={css.image}
                alt={alt}
                image={images[2]}
                variants={getVariants(images[2])}
              />
            </div>
          </div>
          <div className={css.container}>
            <div className={css.imgWrapper}>
              <ResponsiveImage
                className={css.image}
                alt={alt}
                image={images[3]}
                variants={getVariants(images[3])}
              />
            </div>
            <div className={css.imgWrapper}>
              <ResponsiveImage
                className={css.image}
                alt={alt}
                image={images[4]}
                variants={getVariants(images[4])}
              />
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

ImageGallery.defaultProps = {
  className: null,
  rootClassName: null,
  images: null,
  noImageMessage: null,
};

ImageGallery.propTypes = {
  className: string,
  rootClassName: string,
  alt: string.isRequired,
  images: arrayOf(oneOfType([propTypes.image, propTypes.imageAsset])),
  noImageMessage: string,
};

export default ImageGallery;
